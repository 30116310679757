import { Box, Paper } from "@mui/material";
import "../../style/staff.css";
import CarrerTable from "../common/CareerTable";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import FeeTable from "../common/FeeTable";
function checkFeeTobeShow(fees) {
  //여러 항목 중, 단 하나의 항목만이라도 채워져있다면 보임
  const values = Object.values(fees);
  if (values.filter((v) => v).length === 0) {
    return false;
  } else {
    return true;
  }
}
function makeRowData(fees) {
  const feeArr = [
    [
      fees.educationFee || "-",
      fees.gameFee || "-",
      fees.uniformFee || "-",
      fees.summerTrainingFee || "-",
      fees.winterTrainingFee || "-",
      fees.etcFee || "-",
    ],
  ];
  console.log("feeArr", feeArr);
  return feeArr;
}
export default function TeamFee({ team, margin }) {
  const headers = [
    "월교육비",
    "대회(1일)",
    "피복(동하계)",
    "하계훈련(일)",
    "동계훈련(일)",
    "기타비용(일)",
  ];
  return (
    <Box margin={margin ? margin : "1rem"}>
      <Box sx={{ fontSize: "1rem", color: "#777" }}>단원:만원</Box>
      {checkFeeTobeShow(team.proClubFee) ? (
        <FeeTable
          hHeaders={headers}
          data={makeRowData(team.proClubFee)}
        ></FeeTable>
      ) : null}
    </Box>
  );
}
