import { useNavigationType, useParams } from "react-router";
import TeamContact from "./TeamContact";
import TeamFee from "./TeamFee";
import TeamPlayerNum from "./TeamPlayerNum";
import TeamProfile from "./TeamProfile";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../config";

import PlayerVideo from "../person/PlayerVideo";
import { Box, Typography } from "@mui/material";
import TitleBox from "../../LayoutContainers/TitleBox";
import RowBox from "../../LayoutContainers/RowBox";
import TitleRowBox from "../../LayoutContainers/TitleRowBox";
import GalleryList from "./GalleryList";
import { BackHistoryContext } from "../../BackHistory/BackHistoryProvider";
import { useBackHistoryActions } from "../../BackHistory/UseBackControll";
import { leagueGrade } from "../../recoil/commonEnum";
import { teamBackAtom } from "../../recoil/atoms/teamBack";
import { useRecoilState } from "recoil";

export default function TeamDetail() {
  const [team, setTeam] = useState(null);
  const { teamId } = useParams();
  const [teamBack, setTeamBack] = useRecoilState(teamBackAtom);
  const navigationType = useNavigationType();
  async function fetchdata() {
    const {
      data: { data },
    } = await axios.get(`${API.TEAM_DETAIL}/${teamId}`);
    setTeam(data);
  }
  useEffect(() => {
    if (navigationType === "PUSH") {
      fetchdata();
    } else {
      fetchdata();
      setTimeout(
        () =>
          window.scrollTo({
            top: parseInt(teamBack.scrollY),
            behavior: "instant",
          }),
        100
      );
    }
  }, []);

  return (
    <Box>
      <TitleBox title="TEAM"></TitleBox>
      {team ? (
        <Box>
          <RowBox padding="0 0 3rem" mode="light" isBorder={true}>
            <TeamProfile team={team}></TeamProfile>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              Contact
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <TeamContact team={team}></TeamContact>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              활동 비용
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="0"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <TeamFee team={team}></TeamFee>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              갤러리
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <GalleryList imgList={team.images}></GalleryList>
          </RowBox>
          <TitleRowBox>
            <Typography
              variant="mainDetailSectionTitle"
              sx={{ display: "inline-block", marginBottom: "0.5rem" }}
            >
              하이라이트
            </Typography>
          </TitleRowBox>
          <RowBox
            padding="0.3rem 0"
            sidePadding="2rem"
            margin="0 0 1rem 0"
            mode="light"
            isBorder={true}
          >
            <Box sx={{ margin: "1rem 0" }}>
              <PlayerVideo
                playerVideo={team.highlightVideos}
                btnClassName={"team-highlight"}
              ></PlayerVideo>
            </Box>
          </RowBox>
          {leagueGrade[team.league.name] ? (
            <Box>
              <TitleRowBox>
                <Typography
                  variant="mainDetailSectionTitle"
                  sx={{ display: "inline-block", marginBottom: "0.5rem" }}
                >
                  선수단
                </Typography>
              </TitleRowBox>
              <RowBox
                padding="0.3rem 0"
                sidePadding="0rem"
                margin="0 0 1rem 0"
                mode="light"
                isBorder={true}
              >
                <TeamPlayerNum
                  players={team.affiliatingProPlayers}
                  league={team.league.name}
                  setTeamBack={setTeamBack}
                ></TeamPlayerNum>
              </RowBox>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
